<template>
    <v-sheet
        color="transparent"
        class="d-flex align-center cursor-pointer"
        :width="width"
        height="30"
        @click="onClick"
    >
        <span class="caption font-weight-bold">
            <slot></slot>
        </span>
        <v-icon small>{{ icon }}</v-icon>
    </v-sheet>
</template>

<script>
export default {
    name: 'MediaTableHeaderItem',
    props: {
        value: {
            type: String,
            required: true
        },
        width: {
            type: [String, Number],
            default: 56
        }
    },
    computed: {
        icon() {
            if(!this.direction) {
                return 'mdi-menu-swap'
            }

            return this.direction === 'asc'
                ? 'mdi-menu-down'
                : 'mdi-menu-up'
        }
    },
    data() {
        return {
            direction: null
        }
    },
    methods: {
        onClick() {
            if(this.direction === null) {
                this.direction = 'asc'
            } else if(this.direction === 'asc') {
                this.direction = 'desc'
            } else if(this.direction === 'desc') {
                this.direction = null
            }

            this.$emit('click', this.value, this.direction)
        }
    }
}
</script>

<style lang=scss>

</style>
